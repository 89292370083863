import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchInvoiceTemplates(ctx, params) {
      return useJwt.getInvoiceTemplates(params).then(response => response);
    },
    createInvoiceTemplate(ctx, data) {
      return useJwt.createInvoiceTemplate(data).then(response => response);
    },
    updateInvoiceTemplate(ctx, data) {
      return useJwt.updateInvoiceTemplate(data).then(response => response);
    },
    previewInvoiceTemplate(ctx, id) {
      return useJwt.previewInvoiceTemplate(id).then(response => response);
    },
    deleteInvoiceTemplates(ctx, data) {
      return useJwt.deleteInvoiceTemplates(data).then(response => response);
    },
  },
};
