import { ref } from '@vue/composition-api';
import store from '@/store';
import invoiceTemplateStoreModule from '../invoiceTemplateStoreModule';

export default function useInvoiceTemplatePreviewModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'invoiceTemplate';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, invoiceTemplateStoreModule);
  }

  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
  };
  const pdfUrl = ref(null);
  const isLoading = ref(false);

  const resetModal = () => {
    resetItemLocal();
  };

  const fetchPdfPreviewUrl = () => {
    resetModal();
    isLoading.value = true;
    if (!itemLocal || !itemLocal.value || !itemLocal.value.id) {
      pdfUrl.value = null;
      isLoading.value = false;
      return;
    }
    if (itemLocal.value.previewUrl) {
      pdfUrl.value = itemLocal.value.previewUrl;
      isLoading.value = false;
      return;
    }
    store.dispatch('invoiceTemplate/previewInvoiceTemplate', itemLocal.value.id).then(response => {
      pdfUrl.value = response.data;
      isLoading.value = false;
    });
  };

  const downloadTemplate = () => {
    window.location.href = itemLocal.value.url;
  };

  return {
    refModal,
    refForm,
    itemLocal,
    pdfUrl,
    isLoading,
    fetchPdfPreviewUrl,
    resetItemLocal,
    resetModal,
    downloadTemplate,
  };
}
