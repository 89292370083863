<template>
  <div>
    <div class=" mb-1">
      {{ t('Chọn file') }}
    </div>
    <div
      :class="['block-action', { drag: isUpload }]"
      @dragover.prevent="dragover"
      @drop.prevent="onDropFile"
      @dragleave="dragleave"
    >
      <feather-icon
        v-if="fileName"
        icon="XCircleIcon"
        class="icon-remove"
        @click="removeFile"
      />
      <input
        id="file-input"
        ref="input"
        type="file"
        name="file-input"
        class="d-none"
        :accept="accept"
        @change="onChangeFile"
      >
      <template v-if="isUpload">
        {{ t('Thả file tại đây') }}.
      </template>
      <template v-else>
        <div
          v-if="!fileUpload"
          class="d-flex flex-column align-items-center"
        >
          <p>{{ t('Kéo thả file ở đây') }}</p>
          <p>{{ t('Hoặc') }}</p>
        </div>
        <div
          v-else
          class="text-file"
        >
          <p class="mb-0">
            {{ fileName }}
          </p>
          <p>
            {{ fileSize }}
          </p>
        </div>

        <label for="file-input"> {{ t('Chọn File') }} </label>
      </template>
    </div>
  </div>

</template>

<script>
import { VBModal } from "bootstrap-vue";
import { useUtils as useI18nUtils } from '@core/libs/i18n';

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    VBModal,
  },
  directives: {
    "b-modal": VBModal,
  },
  props: {
    error: {
      type: String,
      default: null,
    },
    accept: {
      type: String,
      default: "application/vnd.ms-excel",
    },
  },
  data() {
    return {
      isUpload: false,
      errorMessage: "",
      fileUpload: null,
    };
  },
  setup() {
    const { t } = useI18nUtils();
    return { t };
  },
  computed: {
    fileName() {
      if (!(this.fileUpload instanceof File)) return "";
      return this.fileUpload.name;
    },
    fileSize() {
      if (!(this.fileUpload instanceof File)) return "";
      return this.getFileSize(this.fileUpload.size);
    },
  },
  methods: {

    onFileChanged() {
      this.$emit('input', this.fileUpload);
    },
    dragover() {
      if (!this.fileName) this.isUpload = true;
    },
    dragleave() {
      if (!this.fileName) this.isUpload = false;
    },
    onDropFile(event) {
      const { files } = event.dataTransfer;
      this.handleUploadFile(files);
      this.onFileChanged();
    },
    onChangeFile({ target }) {
      const { files } = target;
      if (!files || !files.length) return;
      this.handleUploadFile(files);
    },
    handleUploadFile(files) {
      if (!files || !files.length) return;
      const file = files[0];
      // if (!file.name.toLowerCase().match(/.*\.(xlsx|xls|xltx)/g)) {
      //   this.removeFile();
      //   this.errorMessage = "file không đúng định dạng!";
      // } else {

      // }
      this.errorMessage = "";
      this.$refs.input.files = files;
      this.fileUpload = file;
      this.isUpload = false;
      this.onFileChanged();
    },
    removeFile() {
      if (this.$refs.input) {
        this.$refs.input.value = "";
      }
      this.isUpload = false;
      this.errorMessage = "";
      this.fileUpload = null;
      this.onFileChanged();
    },
    getFileSize(size, decimal = 1) {
      const BYTES = 1024;
      if (size >= BYTES ** 2) {
        const val = (size / BYTES ** 2).toFixed(decimal);
        return `${val}MB`;
      }
      if (size >= BYTES) {
        const val = (size / BYTES).toFixed(decimal);
        return `${val}KB`;
      }
      const val = size.toFixed(decimal);
      return `${val}B`;
    },
  },
};
</script>

<style lang="scss" scoped>
.block-action {
  position: relative;
  border: 1px dashed grey;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-bottom: 20px;
  height: 150px;
  border-radius: 5px;

  &.drag {
    background-color: rgba(128, 128, 128, 0.616);
    color: white;
    border-color: white;
  }

  label {
    background: #248f55;
    padding: 5px 20px;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    box-shadow: 0 0 5px #00000040;

    &:hover {
      box-shadow: 0 0 0;
    }
  }
}
.link-download-template {
  color: #248f55;
  cursor: pointer;
}
.icon-remove {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 5px;
  right: 5px;
}
.text-file {
  p {
    text-align: center;

    &:first-child {
      width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
